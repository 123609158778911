
import _, { now } from "lodash";
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

export const page_manifest = {
     title : "Tasks Management",
     layout : layouts.base
};


//
export const cfg_edit = {
     taskID : {
          disabled : true
     }
}

export const cfg_create = {
     taskID : {
          disabled : false
     }  

}

//  for local language
export const lang = {
     en : {
          task_edit_title : "Task Editing"
     },
     tc : {

     }
}
