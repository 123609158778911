
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

export const page_manifest = {
     title : "RUN TASK TEST",
     layout : layouts.base,             // layout type, in layoutTemplate.js
     contents : false                    //  how to render layout
                                        //   function(verb, e) - inline
                                        //   EXTERNAL_URL - route to url by pop up window
                                        //   false - nothing happen ( load verbs/index.html)
};


// form input configurate
//   ie. { 'pj-form-data payload name' : {
//             readonly : bool (readonly)
//             value : (default value)
//             touch : (add pj-form-updated whilt initiate)
//             disabled : bool (disable input)
//     }
