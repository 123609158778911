
//   rev 22.06.30
//   language pack control

import * as langs from './langs/*.js';       // load all verb settings
import verb_lang from '../verbs/*/settings.js';
// language pack
// attched, language

let lang = {
     ...langs.en.pack,
//     ...langs.tc.pack
};

let final_lang = lang;

// merge lang pack under verbs folder
//
for (var v in verb_lang) {
     if (v.substr(0,1) !== "_") {
          if (verb_lang[v]["lang"] !== undefined) {
               final_lang = {
                    ...final_lang,
                    ...verb_lang[v]["lang"].en
                    // ...verb_lang[v]["lang"].tc
               }
          }
     }
}

export default final_lang;
