
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

//  for local language
export const lang = {
     en : {
          form_edit_title : "Editing",
          page_header_title : "Control Panel"
     },
     tc : {

     }
}


export const page_manifest = {
     title : false,
     layout : layouts.base,             // layout type, in layoutTemplate.js
     contents : false                    //  how to render layout
                                        //   function(verb, e) - inline
                                        //   EXTERNAL_URL - route to url by pop up window
                                        //   false - nothing happen ( load verbs/index.html)
};


//   all verb mapping to CRUD
export const verbMapper = {
     create : "",
     edit : "",
     update : "controlPanelUpdate",
     remove : "",
     triggerControl : "controlPanelTrigger"
}


// form input configurate
//   ie. { 'pj-form-data payload name' : {
//             readonly : bool (readonly)
//             value : (default value)
//             touch : (add pj-form-updated whilt initiate)
//             disabled : bool (disable input)
//     }

// for form edit
export const cfg_edit = {};

// for form create
export const cfg_create = {};

//   rev 23.03.22
// for table header
//   structure :
//        quickMenu : {
//             [payload] : { label, icon, verb, bulk}
//        }
//
export const cfg_tableheader = {
     quickMenu : []
}


