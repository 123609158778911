
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

export const page_manifest = {
     title : "Backup / Restore",
     layout : layouts.base,             // layout type, in layoutTemplate.js
     contents : false                    //  how to render layout
                                        //   function(verb, e) - inline
                                        //   EXTERNAL_URL - route to url by pop up window
                                        //   false - nothing happen ( load verbs/index.html)
};


export const verbMapper = {
     create : "backupCreate",
     edit : "backupEdit",
     update : "backupUpdate",
     remove : "backupRemove",
     import : "backupImport",
     restore : "backupRestore",
     download : "backupDownload"
}

// form input configurate
//   ie. { 'pj-form-data payload name' : {
//             label : str (available for button)
//             readonly : bool (readonly)
//             value : (default value)
//             touch : (add pj-form-updated whilt initiate)
//             disabled : bool (disable input)
//     }

// for form edit
export const cfg_edit = {
     "save" : {
          label : "Update"
     }
};

// for form create
export const cfg_create = {
     "save" : {
          label : "Create Backup"
     }  ,
     "restoreBackup" : {
          disabled : 1
     }
};

//   rev 23.03.22
// for table header
//   structure :
//        quickMenu : {
//             [payload] : { label, icon, verb, bulk}
//        }
//
export const cfg_tableheader = {
     quickMenu : [
                    {
                         action : "create",           // action (payload) for action handling
                         label : "Create",
                         icon : "icon-file-plus",
                         verb : verbMapper.create,
                         bulk : false             // allow bluk operation
                    },
                    {
                         action : "import",           // action (payload) for action handling
                         label : "Import",
                         icon : "icon-database-add",
                         verb : verbMapper.import,
                         bulk : false             // allow bluk operation
                    },
                    { 
                         action : "--"
                    },                         // divider
                    {
                         action : "remove",
                         label : "Remove",
                         icon : "icon-trash",
                         verb : verbMapper.remove,
                         bulk : true
                    }
               ]
}

//  for local language
export const lang = {
     en : {
          form_edit_title : "Editing"
     },
     tc : {

     }
}
