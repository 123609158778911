
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

export const page_manifest = {
     title : "System User Group",
     layout : layouts.base
};



// form input configurate
//   ie. { 'pj-form-data payload name' : {
//             readonly : bool (readonly)
//             value : (default value)
//             touch : (add pj-form-updated whilt initiate)
//             disabled : bool (disable input)
//     }

// for form edit
export const cfg_edit = {
     groupid : {
          readonly : true
     },
};

// for form create
export const cfg_create = {
     groupid : {
          readonly : false
     },
     active : {
          value : 1
     }
};

//  for local language
export const lang = {
     en : {
     },
     tc : {
     }
};
