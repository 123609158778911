//
//   rev 22.06.16
//   a collection of layout / widgets
//
import base from 'bundle-text:../views/content_layout_base.tpl';
import widgetCard from 'bundle-text:../views/content_layout_widget_card.tpl';

import widgetCardGeneral from 'bundle-text:../views/content_layout_widget_card_general.tpl';
import widgetPagination from 'bundle-text:../views/content_layout_widget_pagination.tpl';
import widgetPaginationInfo from 'bundle-text:../views/content_layout_widget_paginationInfo.tpl';
import widgetTableHeader from 'bundle-text:../views/content_layout_widget_tableHeader.tpl';


export {
     base,
     widgetCard,
     widgetCardGeneral,
     widgetPagination, widgetPaginationInfo,
     widgetTableHeader
}
