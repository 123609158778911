import * as config from "./config.js";
import _ from 'lodash';

import Swal from 'sweetalert2';
import bsCustomFileInput from 'bs-custom-file-input';

// import $ from "jquery";

export  {
     log,
     doRequest,
     clearAllSessions,
     getItem, setItem,
     getAppProperty, setAppProperty,
     sendWindowMessage,
     loaderBlock, uploaderBlock,
     getTimeStamp,
     modalForm,
     confirmForm,
     notification,
     triggerPJEvent, registerPJEvent,
     getActiveUserInfo

};

export function sanitizeHTML(text) {
     var element = document.createElement('div');
     element.innerText = text;
     return element.innerHTML;
}


//   rev 22.08.07
//   for pj widget, repalcing container with new context
//   ie. <div id=container></div>  <- targetContainer (DOM)
//    replace with <span>new context</span>
//   and return a referencing to new context container
//
export function replaceWidgetContext(targetContainer, newContext) {

     var objID = randomID(6);
     $(targetContainer).replaceWith($(newContext).attr("id", objID));

     return $(document).find("#" + objID);
}


//   gnerate random ID by given length
//
export function randomID(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *  charactersLength));
   }
   return result;
}

//   currency number display
export function currency_format(num) {

     var v = num || 0;
     return v.toLocaleString(undefined, {
          maximumFractionDigits : 2,
          minimumFractionDigits : 2          
     });
}
//   currency number display
export function number_format(num) {

     var v = num || 0;
     return v.toLocaleString(undefined, {
          maximumFractionDigits : 2,          
     });
}

// init

function getTimeStamp(in_sec) {

     if (in_sec) {
          return _.now() / 1000;
     } else {
          return _.now();
     }
}

//   assign/create an app property (can address globally)
//
function setAppProperty(id,v) {
     window.app = window.app || {};
     if (v == null) {
          delete window.app[id];
     } else {
          window.app[id] = v;
     }
}

//   get app property
function getAppProperty(id) {

     if (window.app == undefined) {
          window.app = {};
     }

     return window.app[id];
}


function getItem(id){

     var v = sessionStorage.getItem(id);
     return JSON.parse(v);
}

function setItem(id, v) {
     if(v) {
          var s = JSON.stringify(v);
          sessionStorage.setItem(id, s);
     } else {
          sessionStorage.removeItem(id);
     }
}


//   rev 22.04.07
//   return userInfo if user login
//   
function getActiveUserInfo(){

     return getItem("userinfo")?.userinfo;
}


//   rev 22.08.01
//   wrapper
//   trigger inter modules event "pjMessageEvent"
//   purpose : calling function in between module
//
function triggerPJEvent(evtName, Params, cbfunc) {

     $(window).trigger("_pjMessageEvent", [
          evtName,
          Params,
          cbfunc
     ]);
}

//   rev 22.08.12
//   register PJ Events
//        idx : event idx (priority)
function registerPJEvent(pjEvts, idx) {

     var pj_events = window._pjEvents || {};

     if (pjEvts) {
          for (var evtName in pjEvts) {

               if (pj_events[evtName] == undefined) {
                    pj_events[evtName] = [];
               }

               if (idx) {
                    pj_events[evtName][idx] = pjEvts[evtName];
               } else {
                    pj_events[evtName].push(pjEvts[evtName]);
               }

               this.log("pjEvent", evtName, "registered");
          }
          window._pjEvents = pj_events;
     }



}


//   rev 22.07.24
//   tpl :
//   settings = {
//        title : "",
//        theme : "info",
//        message : ""
//   }
function notification(settings){

     var titleText = settings.title || "";

     switch(settings.theme) {
          case "warning":
               settings.ui = "bg-warning text-white";
               settings.title = "<i class='icon-warning22 icon-2x'></i> "+ titleText;
               break;
          case "error" :
               settings.ui = "bg-danger text-white";
               settings.title = "<i class='icon-cancel-circle2 icon-2x'></i> "+ titleText;
               break;
          case "message":
               settings.ui = "bg-info text-white";
               settings.title = "<i class='icon-bubble icon-1x'></i> " + titleText;
               settings.time = 10000;
               break;
          case "info":
          default :
               settings.ui = "bg-teal text-white";
               settings.title = "<i class='icon-info22 icon-2x'></i> " + titleText;
     }


     $.jGrowl(settings.message, {
         header: settings.title,
         theme: settings.ui,
         life: settings.time || 3000,
         closer : false,
         click: (e,m,o) => {
               $(e).find('.jGrowl-close').trigger('click');
               if (settings.cbfunc) settings.cbfunc(e,m,o);
          }
    });
}


//   rev 22.07.24
//   confirmForm dialog
//
//   IN : settings = {
//        title     -
//        text      - description
//        icon      - 'warning, error, success, info, and question
//        buttonText - str
//   }

function confirmForm(settings, cbfunc){
     Swal.fire({
       title: settings.title || 'Are you sure?',
       text: settings.text || "",
       icon: settings.icon || 'question',
       showCancelButton: true,
       buttonsStyling: false,
       confirmButtonText: settings.buttonText || 'Confirm',
       customClass: {
         container: '...',
         popup: '...',
         header: '...',
         title: 'pjio-swal2-title',
         closeButton: '...',
         icon: '...',
         image: '...',
         htmlContainer: 'pjio-swal2-html pb-3',
         input: 'form-control',
         inputLabel: '...',
         validationMessage: '...',
         actions: '...',
         confirmButton: 'btn btn-primary mx-1',
         cancelButton: 'btn btn-light mx-1',
         denyButton: 'btn btn-light mx-1',
         loader: '...',
         footer: '....',
         timerProgressBar: '....',
       }
     }).then((result) => {
       if (result.isConfirmed) {

            if (cbfunc) {
                 cbfunc();
            }
       }
 });
}


//   rev 23.06.07
//   modalForm
//   settings : {
//        title - (str) topic
//        buttons : {
//             ok - { title }       // preset button 
//             cancel - { title }   // preset button
//             (xxx) - button settings  // custom defined
//   }
//   tpl : html tpl form
//   cbfunc : call back click submit (out, fm), i,e. fm modal form object
//
//   out : nvp of fields defined pj-modal-value i.e. class="pj-modal-data" payload="f1"
//        ==> { f1 : value}
function modalForm(settings, tpl, cbfunc ) {

     var preset_btns = {
          ok : {
               ok: {
                    label: settings.buttons?.ok?.title || "Ok",
                    className: 'btn-success',
                    callback: (e) => {
     
                         var fm = $("div.bootbox.modal");
                         var v= fm.find(".pj-modal-data");
                         var out = {};
     
                         $.each(v, (idx, elm)=> {
                              if ($(elm).attr("payload") !== undefined) {
                                   out[v.attr("payload")] = $(elm).val();
                              }
                         })
                         if (cbfunc) {
                              cbfunc(out, fm);
                         }
                    }
               }
          },
          cancel : {
               no_ok: {
                    label: settings.buttons?.cancel?.title || "Cancel",
                    className: 'btn-danger',
                    callback: (e) => {
                         if (cbfunc) {
                              cbfunc(false);
                         }
                    }
               }
          }
     };



     var cfg = {
          message: tpl,
          title: settings?.title || "Undefined",
          closeButton: false,                   
          className : () => {
               var sizing = settings?.size || false;
               return sizing ? `modal-size-${sizing}` : "";
          },
          buttons: {},
          onShown: (e) => {
               if (settings.onShown) {
                    settings.onShown(e);
               }
          }
     };

     // merge buttons
     $.each(settings.buttons, (btn_name, ctx) => {

          switch (btn_name) {
               case "ok":
               case "cancel":
                    _.merge(cfg.buttons, preset_btns[btn_name]);
                    break;
               default:
                    _.merge(cfg.buttons, {btn_name : ctx});
          }

     });

     /*
     if (settings.btn_ok) { _.merge(cfg.buttons, btn_ok); }
     if (settings.btn_cancel) { _.merge(cfg.buttons, btn_cancel); }
     */

     // any form element in bootbox init once after shown
     let d = bootbox.dialog(cfg);
     d.on('shown.bs.modal', function(e){          
          // if there is any file input
          bsCustomFileInput.init();
     });     

     d.on('hide.bs.modal', function(e){
         //  alert("before hide");
     });
}



// block Ui
// toggle display block
function loaderBlock(sw) {

     if (!$().block) {
         console.warn('Warning - blockui.min.js is not loaded.');
         return;
    }

    var systemLocked = getItem("SYSTEM_LOCK");

    if (sw) {
          blkmsg = '<div class="loader-spinner"><div class="ldio-hvhpqemq39c"><div></div><div></div></div></div>'

          if (systemLocked) {
               blkmsg += '<h4>Please hold. System is being updated.</h4>'
          }

         $.blockUI({
               message: blkmsg,
               overlayCSS: {
                   backgroundColor: '#000',
                   opacity: 0.95,
                   cursor: 'wait'
               },
               baseZ: 9900,
               ignoreIfBlocked: true,
               css: {
                   color: '#ffffff',
                   padding: 0,
                   border: 0,
                   backgroundColor: 'transparent'
               }
          });
     } else {

          if (!systemLocked){
               $.unblockUI();
          }
     }

}

// block Ui  for file upload
// toggle display block
function uploaderBlock(sw) {

     if (!$().block) {
         console.warn('Warning - blockui.min.js is not loaded.');
         return;
    }

    var systemLocked = getItem("SYSTEM_LOCK");

    if (sw) {
          blkmsg = `<div class="loader-spinner">
                         <h4>Uploading...</h4>
                         <h1 class="display-percentage">0%</h1>
                    </div>`;

          if (systemLocked) {
               blkmsg += '<h4>Please hold. System is being updated.</h4>'
          }

         $.blockUI({
               message: blkmsg,
               overlayCSS: {
                   backgroundColor: '#000',
                   opacity: 0.95,
                   cursor: 'wait'
               },
               baseZ: 9900,
               ignoreIfBlocked: true,
               css: {
                   color: '#ffffff',
                   padding: 0,
                   border: 0,
                   backgroundColor: 'transparent'
               }
          });
     } else {

          if (!systemLocked){
               $.unblockUI();
          }
     }

}

// fire message between windows
function sendWindowMessage(windowName, message) {
     if (windowName == "root") {
          window.top.postMessage(message, "*");
     } else {
          document.getElementById(windowName).contentWindow.postMessage(message, "*");
     }
}

function log() {

     if (config.DEBUG_MODE) {
          console.log.apply(null, arguments);
     }
}





// rev 22.05.06
// generalize all requests

function doRequest(verb, payload, cb) {

     var data_url = config.requestURL + "/" + verb;

     $.ajax({
         type: "POST",
         headers : {
              'Content-Type': 'application/x-www-form-urlencoded'
         },
         url: data_url,
         data: payload,
         dataType: 'text',
　　　　//Allow to carry certificate (PHPSESSID)
         xhrFields: {
            // withCredentials: true
         },
     　　//Allow cross domain
         crossDomain: true,
         success:function(d){
              var out = JSON.parse(_.unescape(d));
              cb(out);
         },
         error:function(e){
               log("fail to load data",e);
          }
     });

     /*
    $.post( data_url, payload, function(d){
         var out = JSON.parse(_.unescape(d));
          cb(out);
    }).fail(function(){
         log("fail to load data");
    });
    */
}

function clearAllSessions(){
     sessionStorage.clear();
     localStorage.clear();
}
