const _temp0 = require("../verbs/accounts/settings.js");
const _temp1 = require("../verbs/accountUsers/settings.js");
const _temp2 = require("../verbs/acGeneralLedger/settings.js");
const _temp3 = require("../verbs/acItemsMaster/settings.js");
const _temp4 = require("../verbs/controlPanel/settings.js");
const _temp5 = require("../verbs/dashboard/settings.js");
const _temp6 = require("../verbs/dbBackup/settings.js");
const _temp7 = require("../verbs/eventLog/settings.js");
const _temp8 = require("../verbs/getAllUsers/settings.js");
const _temp9 = require("../verbs/listJWTHistory/settings.js");
const _temp10 = require("../verbs/runTest/settings.js");
const _temp11 = require("../verbs/supplierPO/settings.js");
const _temp12 = require("../verbs/suppliers/settings.js");
const _temp13 = require("../verbs/taskManager/settings.js");
const _temp14 = require("../verbs/userGroupEdit/settings.js");
const _temp15 = require("../verbs/userProfile/settings.js");
const _temp16 = require("../verbs/verbPermissionEdit/settings.js");
const _temp17 = require("../verbs/_master/settings.js");
module.exports = {
  "accounts": _temp0,
  "accountUsers": _temp1,
  "acGeneralLedger": _temp2,
  "acItemsMaster": _temp3,
  "controlPanel": _temp4,
  "dashboard": _temp5,
  "dbBackup": _temp6,
  "eventLog": _temp7,
  "getAllUsers": _temp8,
  "listJWTHistory": _temp9,
  "runTest": _temp10,
  "supplierPO": _temp11,
  "suppliers": _temp12,
  "taskManager": _temp13,
  "userGroupEdit": _temp14,
  "userProfile": _temp15,
  "verbPermissionEdit": _temp16,
  "_master": _temp17
}