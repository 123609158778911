

const ws_url        = "wss://sg.sysnec.com:54080/pjio/v3";
const request_url  = "https://deel6.sg-host.com";

const DEBUG_MODE = true;

export  {
     DEBUG_MODE,
     ws_url as wsURL,
     request_url as requestURL
}
