
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

export const page_manifest = {
     title : "Users Management",
     layout : layouts.base
};


//
export const cfg_edit = {
     username : {
          readonly : true
     }
}

export const cfg_create = {
     username : {
          readonly : false                   
     },
     title : {
          value : "NEW USER"
     },
     active : {
          value : 1
     },
     "2fa_enabled" : {
          value : 1
     },
     password : {
          touch : 1      // touch the field to be updated
     }
}

//  for local language
export const lang = {
     en : {
          notification_DataSaved : "Saved"
     },
     tc : {

     }
}
