
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

export const page_manifest = {
     title : "Ledget Items Definitions",
     layout : layouts.base,             // layout type, in layoutTemplate.js
     contents : false                    //  how to render layout
                                        //   function(verb, e) - inline
                                        //   EXTERNAL_URL - route to url by pop up window
                                        //   false - nothing happen ( load verbs/index.html)
};

//   all verb mapping to CRUD
export const verbMapper = {
     create : "acItemsCreate",
     edit : "acItemsEdit",
     update : "acItemsUpdate",
     remove : "acItemsRemove",
     fetchItemList : "acItemsLister"
}


// form input configurate
//   ie. { 'pj-form-data payload name' : {
//             readonly : bool (readonly)
//             value : (default value)
//             touch : (add pj-form-updated whilt initiate)
//             disabled : bool (disable input)
//     }

// for form edit
export const cfg_edit = {};

// for form create
export const cfg_create = {
     category : {
          value : 0
     }
};

//   rev 23.03.22
// for table header
//   structure :
//        quickMenu : {
//             [payload] : { label, icon, verb, bulk}
//        }
//
export const cfg_tableheader = {
     quickMenu : [
          {
               action : "create",           // action (payload) for action handling
               label : "Create",
               icon : "icon-file-plus",
               verb : verbMapper.create,
               bulk : false             // allow bluk operation
          },
          { 
               action : "--"
          },                         // divider
          {
               action : "remove",
               label : "Remove",
               icon : "icon-trash",
               verb : verbMapper.remove,
               bulk : true
          },
          {
               action : "activate",
               label : "Enable",
               icon : "icon-checkmark3 text-success",
               verb : verbMapper.update,
               bulk : true
          },
          {
               action : "deactivate",
               label : "Disable",
               icon : "icon-cross2 text-danger",
               verb : verbMapper.update,
               bulk : true
          }
     ]
}


//  for local language
export const lang = {
     en : {
          form_edit_title : "Editing"
     },
     tc : {

     }
}
