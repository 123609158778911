//

export const pack = {

     form_login_title : "帳戶認證",
     form_login_subtitle : "請輸入你的用戶資料",

     form_login_button : "登入",
     form_login_terms : "繼續即表示您確認您已閱讀我們的條款並同意用戶守則",

     dashboardTitle : "主頁",
     user_editing_title : "用戶資料更改",

     event_auth_rejected_topic : "認證已被拒絕",
     event_auth_rejected_body : "你的認證要求不獲主帳戶接受",

     event_user_revoke_topic : "你的帳戶已刪除",
     event_user_revoke_body : "你的帳戶因保安原因已要求刪除",

     event_kickout_topic : "你的帳戶已被登出",
     event_kickout_body  : "原因"
}
