//
//   rev 22.06.14
//   deal with all connection between service server and client
//
import * as config from "./config.js";
import * as utils from "./utilities.js";

import * as jwt from 'jsonwebtoken';

export {
     doQuery,
     doFormUpload
}


//
//   do query to destinated server
//
function doQuery(verb, payload, cbfunc) {

     // if user sn is found, append to verb
     var userinfo = utils.getItem("userinfo");

     var signed_payload = {};

     // add timestamp to payload
     payload._reqtimestamp = Date.now();

    // utils.log(">>>>>", payload);

     if (userinfo && userinfo.verified){
          verb = verb + "/" + userinfo.sessSN;
          signed_payload.token = generateJWT(payload, userinfo);
     } else {
          signed_payload = payload;
     }

     // utils.log("SEND >>>>", signed_payload);
     utils.doRequest(verb, signed_payload, function(e){
          // is query success
          var out_res = {};
          if (e.result) {
               out_res = e.data;
          } else {
               out_res.result = 0;
               out_res.data = {};
               out_res.message = e.message;
          }

          if (cbfunc) {
               cbfunc(out_res);
          }

     });

}


//   rev 23.04.06
//   proceed formData object upload, mainly use for file upload
//
function doFormUpload(formDataObj, cbfunc) {

     var userInfo = utils.getItem("userinfo");     
     var data_url = config.requestURL + "/common/upload/filehandler.php";

     // obtain nonce     
     doQuery("getNonceToken",{},(res) => {

          if (res.result) {

               var nonce = res.data;

               formDataObj.append("nonce", nonce); 
               formDataObj.append("sessSN", userInfo.sessSN);

               $.ajax({
                    url: data_url,
                    type: 'POST',
                    data: formDataObj,
                    processData: false,
                    contentType: false,
                    xhr: function () { 
                         var xhr = $.ajaxSettings.xhr();

                         xhr.upload.onprogress = function (e) {
                              // For uploads
                              if (e.lengthComputable) {
                                   percentage = Math.round(100*(e.loaded / e.total));
                                   // update uploaderBlock value
                                   $(".blockUI .loader-spinner .display-percentage").html(percentage + "%");          
                              }
                         };
                         return xhr;
                    },
                    beforeSend: ()=> {
                         utils.uploaderBlock(1);                         
                    }
               }).done(function(e){
                    utils.uploaderBlock(0);

                    var out = JSON.parse(e);

                    if (cbfunc) {
                         cbfunc(out);
                    }
               })
          } 
     });

}


//
//   private function, generte JWT base on userInfo
//
function generateJWT(payload, userinfo){

     if (userinfo){
          var signkey = userinfo.token;

          var formatted_payload = {
               "caller" : userinfo.usersn,
               "data"   : payload
          }

          return jwt.sign(formatted_payload, signkey, { algorithm: 'HS512' });

     }
     return false;
}
